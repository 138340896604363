<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>

    <b-row class="mb-2">
      <b-col sm="6" lg="12" >
        <center class="d-flex justify-content-center mb-4" style="gap:10px">
          <b-button
            class
            type="submit"
            @click="getDashboardData('Day')"
            size="md"
            variant="primary"
          >
            <i class="fa fa-dot-circle-o"></i> Day
          </b-button>
          <b-button
            class
            type="submit"
            @click="getDashboardData('Week')"
            size="md"
            variant="primary"
          >
            <i class="fa fa-dot-circle-o"></i> Week
          </b-button>
          <b-button
            class
            type="submit"
            size="md"
            @click="getDashboardData('Month')"
            variant="primary"
          >
            <i class="fa fa-dot-circle-o"></i> Month
          </b-button>
        </center>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">Transaction From Date :</label>
              <b-form-input
                type="date"
                class="input_height"
                v-model="FromDate"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="3">
            <b-form-group>
              <label for="to-date">Transaction To Date :</label>
              <b-form-input
                type="date"
                class="input_height"
                v-model="ToDate"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">Select Cinema Name</label>
              <b-form-select
                required
                v-model="selectedCinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="cinemaList"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="Action">Action</label>
              <br />
              <b-button
                class="input_height"
                type="submit"
                size="md"
                @click="getDashboardData('Range')"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i> Submit
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <!-- <b-dropdown class="float-right" variant="transparent p-0" right>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here...</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>-->
            <h2 class="mb-0">{{ rangeData.Transactions }}</h2>
            <p class="my-2">Transactions</p>
          </b-card-body>
          <!-- <card-line1-chart-example
            chartId="card-chart-01"
            class="chart-wrapper px-3"
            style="height:70px;"
            :height="70"
          />-->
        </b-card>
      </b-col>
      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <!-- <b-dropdown class="float-right" id="customId" variant="transparent p-0" right no-caret>
              <template slot="button-content">
                <i class="icon-location-pin"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here...</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>-->
            <h2 class="mb-0">{{ rangeData.NoofTicket }}</h2>
            <p class="my-2">Tickets</p>
          </b-card-body>
          <!-- <card-line2-chart-example
            chartId="card-chart-02"
            class="chart-wrapper px-3"
            style="height:70px;"
            :height="70"
          />-->
        </b-card>
      </b-col>
      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <!-- <b-dropdown class="float-right" variant="transparent p-0" right>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here...</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>-->
            <h2 class="mb-0">{{ rangeData.TicketAmount }}</h2>
            <p class="my-2">Ticket Amount</p>
          </b-card-body>
          <!-- <card-line3-chart-example
            chartId="card-chart-03"
            class="chart-wrapper"
            style="height:70px;"
            height="70"
          />-->
        </b-card>
      </b-col>
      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <!-- <b-dropdown class="float-right" variant="transparent p-0" right>
              <template slot="button-content">
                <i class="icon-settings"></i>
              </template>
              <b-dropdown-item>Action</b-dropdown-item>
              <b-dropdown-item>Another action</b-dropdown-item>
              <b-dropdown-item>Something else here...</b-dropdown-item>
              <b-dropdown-item disabled>Disabled action</b-dropdown-item>
            </b-dropdown>-->
            <h2 class="mb-0" v-if="rangeData.NoofTicket > 0">{{
              parseFloat(rangeData.NoofTicket / rangeData.Transactions).toFixed(
                2
              )
            }}</h2>
            <h2 class="mb-0" v-else>0</h2>
            <p class="mb-0">Ticket / Transactions</p>
          </b-card-body>
          <!-- <card-line3-chart-example
            chartId="card-chart-03"
            class="chart-wrapper"
            style="height:70px;"
            height="70"
          />-->
        </b-card>
      </b-col>

      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <h2 class="mb-0" v-if="RepeatUserData.length > 0">{{
              RepeatUserData.length
            }}</h2>
            <h2 class="mb-0" v-else>0</h2>
            <p>Repeat Users</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="2">
        <b-card no-body class="bg-dark h-75">
          <b-card-body class="pb-0 text-center">
            <h2 class="mb-0">{{ VoucherStats }}</h2>
            <p>Vouchers Sold</p>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Movie Name</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Tickets</th>
              </tr>
              <tr v-for="(item, index) in data">
                <td v-if="item.MovieName == 'Refund'">
                  <strong>{{ item.MovieName }}</strong>
                </td>
                <td v-else>{{ item.MovieName }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="6" lg="4" class="d-none">
        <b-card no-body class="bg-dark">
          <b-card-body class="pb-0">
            <div v-for="(item, key) in OrganizationArray">
              <!-- Using modifiers -->
              <p>
                <strong>
                  <a
                    style="color: #fff !important; text-decoration: underline"
                    v-b-toggle="'abc' + key"
                    class="m-1"
                    >{{ key }}</a
                  >
                </strong>
              </p>

              <!-- Element to collapse -->
              <b-collapse :id="'abc' + key">
                <b-card style="background: transparent">
                  <table style="width: 100%; border: 1" border="1">
                    <tr>
                      <th>Cinema Name</th>
                      <th>Transaction</th>
                      <th class="text-center">Tickets</th>
                    </tr>
                    <tr v-for="(val, valkey) in item">
                      <td>{{ val.CinemaName }}</td>
                      <td>{{ val.TransactionCount }}</td>
                      <td class="text-center">{{ val.NoofTicket }}</td>
                    </tr>
                  </table>
                </b-card>
              </b-collapse>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-danger">
          <b-card-body class="pb-0">
            <table style="width: 100%; border: 1">
              <tr>
                <th>Organization Name</th>
                <th>Transaction</th>
                <th class="text-center">Tickets</th>
              </tr>
            </table>
            <div v-for="(item, key) in OrgData">
              <!-- Using modifiers -->
              <p>
                <strong>
                  <a
                    style="color: #fff !important; text-decoration: underline"
                    v-b-toggle="'abc' + item.OrganizationName"
                    class="m-1"
                  >
                    <table style="width: 100%; border: 1">
                      <tr>
                        <td style="width: 60%" class>{{
                          item.OrganizationName
                        }}</td>
                        <td style="width: 20%" class>{{
                          item.TransactionCount
                        }}</td>
                        <td style="width: 20%" class>{{ item.NoofTicket }}</td>
                      </tr>
                    </table>
                  </a>
                </strong>
              </p>

              <!-- Element to collapse -->
              <b-collapse :id="'abc' + item.OrganizationName">
                <b-card style="background: transparent">
                  <table style="width: 100%; border: 1" border="1">
                    <tr>
                      <th>Cinema Name</th>
                      <th>Transaction</th>
                      <th class="text-center">Tickets</th>
                    </tr>
                    <tr v-for="(val, valkey) in item.Cinema">
                      <td>{{ val.CinemaName }}</td>
                      <td>{{ val.TransactionCount }}</td>
                      <td class="text-center">{{ val.NoofTicket }}</td>
                    </tr>
                  </table>
                </b-card>
              </b-collapse>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Cinema Name</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Tickets</th>
              </tr>
              <tr v-for="(item, index) in PaymentTypeData">
                <td>{{ item.PaymentType }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Payment Type</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Tickets</th>
              </tr>
              <tr v-for="(item, index) in PaymentTypeData">
                <td>{{ item.PaymentType }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-warning">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Item</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Amount</th>
              </tr>
              <tr v-for="(item, index) in ConsessionData">
                <td v-if="item.Item.includes('Total')">
                  <strong>{{ item.Item }}</strong>
                </td>
                <td v-else>{{ item.Item }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.Amount }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-info">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Genre Name</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Tickets</th>
              </tr>
              <tr v-for="(item, index) in GenreData">
                <td>{{ item.GenreName }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-warning">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Source Name</th>
                <th class="text-center" style="width: 20%">Transactions</th>
                <th class="text-center" style="width: 20%">Tickets</th>
              </tr>
              <tr v-for="(item, index) in SourceData">
                <td>{{ item.SourceName }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-danger">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Day</th>
                <th class="text-center" style="width: 20%">Tickets</th>
                <th class="text-center" style="width: 20%">Percentage</th>
              </tr>
              <tr v-for="(item, index) in DaysData">
                <td>{{ item.DayName }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
                <td class="text-center">{{ item.Percentage }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col sm="6" lg="4" class>
        <b-card no-body class="bg-danger">
          <b-card-body class="pb-0">
            <table style="width: 100%">
              <tr>
                <th style="width: 60%">Date</th>
                <th class="text-center" style="width: 20%">Transaction</th>
                <th class="text-center" style="width: 20%">Ticket</th>
              </tr>
              <tr v-for="(item, index) in TicketSoldData">
                <td>{{ item.TransactionDate }}</td>
                <td class="text-center">{{ item.TransactionCount }}</td>
                <td class="text-center">{{ item.NoofTicket }}</td>
              </tr>
            </table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card class="d-none">
      <b-card-header>
        <b-row>
          <b-col> <i class="icon-menu mr-1"></i>Show Date Report </b-col>
          <b-col class="col d-flex justify-content-end">
            &nbsp;&nbsp;
            <b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false : true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
          ></v-client-table>
        </div>
      </b-card-body>
    </b-card>

    <b-card class="d-none">
      <b-row>
        <b-col sm="5">
          <h4 id="traffic" class="card-title mb-0">Traffic</h4>
          <div class="small text-muted">November 2017</div>
        </b-col>
        <b-col sm="7" class="d-none d-md-block">
          <!-- <b-button type="button" variant="primary" class="float-right">
            <i class="icon-cloud-download"></i>
          </b-button>-->
          <b-button-toolbar
            class="float-right"
            aria-label="Toolbar with buttons group"
          >
            <b-form-radio-group
              class="mr-3"
              id="radiosBtn"
              buttons
              button-variant="outline-secondary"
              v-model="selected"
              name="radiosBtn"
            >
              <b-form-radio class="mx-0" value="Day">Day</b-form-radio>
              <b-form-radio class="mx-0" value="Month">Month</b-form-radio>
              <b-form-radio class="mx-0" value="Year">Year</b-form-radio>
            </b-form-radio-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
      <main-chart-example
        chartId="main-chart-01"
        class="chart-wrapper"
        style="height: 300px; margin-top: 40px"
        height="300"
      ></main-chart-example>
      <div slot="footer">
        <b-row class="text-center">
          <b-col class="mb-sm-2 mb-0">
            <div class="text-muted">Visits</div>
            <strong>29.703 Users (40%)</strong>
            <b-progress
              height="{}"
              class="progress-xs mt-2"
              :precision="1"
              variant="success"
              :value="40"
            ></b-progress>
          </b-col>
          <b-col class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Unique</div>
            <strong>24.093 Users (20%)</strong>
            <b-progress
              height="{}"
              class="progress-xs mt-2"
              :precision="1"
              variant="info"
              :value="20"
            ></b-progress>
          </b-col>
          <b-col class="mb-sm-2 mb-0">
            <div class="text-muted">Pageviews</div>
            <strong>78.706 Views (60%)</strong>
            <b-progress
              height="{}"
              class="progress-xs mt-2"
              :precision="1"
              variant="warning"
              :value="60"
            ></b-progress>
          </b-col>
          <b-col class="mb-sm-2 mb-0">
            <div class="text-muted">New Users</div>
            <strong>22.123 Users (80%)</strong>
            <b-progress
              height="{}"
              class="progress-xs mt-2"
              :precision="1"
              variant="danger"
              :value="80"
            ></b-progress>
          </b-col>
          <b-col class="mb-sm-2 mb-0 d-md-down-none">
            <div class="text-muted">Bounce Rate</div>
            <strong>Average Rate (40.15%)</strong>
            <b-progress
              height="{}"
              class="progress-xs mt-2"
              :precision="1"
              :value="40"
            ></b-progress>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import CardLine1ChartExample from "./Dashboard/CardLine1ChartExample";
import CardLine2ChartExample from "./Dashboard/CardLine2ChartExample";
import CardLine3ChartExample from "./Dashboard/CardLine3ChartExample";
import CardBarChartExample from "./Dashboard/CardBarChartExample";
import MainChartExample from "./Dashboard/MainChartExample";
import SocialBoxChartExample from "./Dashboard/SocialBoxChartExample";
import CalloutChartExample from "./Dashboard/CalloutChartExample";
import MasterService from "@/services/MasterService";
import BarChart from "./Dashboard/Chart.vue";
import { Callout } from "@coreui/vue";
import jsPDF from "jspdf";
import { Event } from "vue-tables-2";
import { EventBus } from "@/main";
import "jspdf-autotable";
import CinemaService from "@/services/CinemaService";

export default {
  name: "dashboard",
  components: {
    Callout,
    CardLine1ChartExample,
    CardLine2ChartExample,
    CardLine3ChartExample,
    CardBarChartExample,
    MainChartExample,
    SocialBoxChartExample,
    CalloutChartExample,
    Event,
    BarChart,
  },
  data: function () {
    return {
      Range: "Day",
      userName: this.$cookie.get("TICUN"),
      dashData: [],
      rangeData: [],
      OrganizationData: [],
      OrganizationArray: [],
      OrgData: [],
      PaymentTypeData: [],
      ConsessionData: [],
      RepeatUserData: [],
      GenreData: [],
      SourceData: [],
      DaysData: [],
      TicketSoldData: [],
      VoucherStats: "0",
      selected: "Month",
      ToDate: "",
      FromDate: "",
      loaded: false,
      chartdata: {
        labels: [],
        datasets: [],
      },
      tableItems: [
        {
          avatar: { url: "img/avatars/1.jpg", status: "success" },
          user: {
            name: "Yiorgos Avraamu",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "USA", flag: "us" },
          usage: { value: 50, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Mastercard", icon: "fa fa-cc-mastercard" },
          activity: "10 sec ago",
        },
        {
          avatar: { url: "img/avatars/2.jpg", status: "danger" },
          user: {
            name: "Avram Tarasios",
            new: false,
            registered: "Jan 1, 2015",
          },
          country: { name: "Brazil", flag: "br" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Visa", icon: "fa fa-cc-visa" },
          activity: "5 minutes ago",
        },
        {
          avatar: { url: "img/avatars/3.jpg", status: "warning" },
          user: { name: "Quintin Ed", new: true, registered: "Jan 1, 2015" },
          country: { name: "India", flag: "in" },
          usage: { value: 74, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Stripe", icon: "fa fa-cc-stripe" },
          activity: "1 hour ago",
        },
        {
          avatar: { url: "img/avatars/4.jpg", status: "" },
          user: { name: "Enéas Kwadwo", new: true, registered: "Jan 1, 2015" },
          country: { name: "France", flag: "fr" },
          usage: { value: 98, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "PayPal", icon: "fa fa-paypal" },
          activity: "Last month",
        },
        {
          avatar: { url: "img/avatars/5.jpg", status: "success" },
          user: {
            name: "Agapetus Tadeáš",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Spain", flag: "es" },
          usage: { value: 22, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Google Wallet", icon: "fa fa-google-wallet" },
          activity: "Last week",
        },
        {
          avatar: { url: "img/avatars/6.jpg", status: "danger" },
          user: {
            name: "Friderik Dávid",
            new: true,
            registered: "Jan 1, 2015",
          },
          country: { name: "Poland", flag: "pl" },
          usage: { value: 43, period: "Jun 11, 2015 - Jul 10, 2015" },
          payment: { name: "Amex", icon: "fa fa-cc-amex" },
          activity: "Last week",
        },
      ],
      tableFields: {
        avatar: {
          label: '<i class="icon-people"></i>',
          class: "text-center",
        },
        user: {
          label: "User",
        },
        country: {
          label: "Country",
          class: "text-center",
        },
        usage: {
          label: "Usage",
        },
        payment: {
          label: "Payment method",
          class: "text-center",
        },
        activity: {
          label: "Activity",
        },
      },
      data: [],
      columns: ["Sr", "MovieName", "NoofTicket", "TicketPrice"],
      options: {
        headings: {
          Sr: "Sr. No.",
          MovieName: "MovieName",
          NoofTicket: "Ticket",
          TicketPrice: "TicketPrice",
        },

        sortable: ["Sr", "MovieName", "NoofTicket", "TicketPrice"],
        filterable: ["Sr", "MovieName", "NoofTicket", "TicketPrice"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      theme: "bootstrap4",
      cinemaList: [],
      selectedCinema: 0,
    };
  },
  mounted() {
    this.getDashboardList();
    this.getCinemasDDL();
    this.getDashboardData(this.Range);
  },
  methods: {
    getCinemasDDL: function (OrgID) {
      // let res = await CinemaService.getCinemasDDL(id);
      //MovieService.getMoviesAccordingCinemaForTran({})
      // this.cinemaList = [];
      let payload = {
        OrgID,
      };
      CinemaService.getCinemasDDL(payload)
        .then((response) => {
          const { data } = response;
          if (data && data.Status) {
            console.log({ data });
            //this.cinemaList = data.Cinemas;
            this.cinemaList = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message || "Please try again");
          }
        })
        .catch((error) => {
          console.log("Catch on AddCinema-OnSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    createPDF: function () {
      let pdfName = "test";
      var doc = new jsPDF();
      // doc.text("Hello World", 10, 10);
      doc.autoTable({
        head: [["Name", "Email", "Country"]],
        body: [
          ["David", "david@example.com", "Sweden"],
          ["Castille", "castille@example.com", "Norway"],
        ],
      });
      // console.log("???????");
      doc.save(pdfName + ".pdf");
    },
    getDashboardList: function () {
      // let res = await MasterService.getDashboard();
      this.dashData = [];
      MasterService.getDashboard()
        .then((response) => {
          const { data } = response;
          if (data.Status && data.DashboardList.length > 0) {
            this.dashData = data.DashboardList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getDashboardData: function (rangeParam) {
      let payload = {};
      payload["Range"] = rangeParam;
      if (rangeParam == "Range") {
        if (this.FromDate.length > 0) {
          payload["FromDate"] = this.FromDate;
        }
        if (this.ToDate.length > 0) {
          payload["ToDate"] = this.ToDate;
        }
      }
      MasterService.getDashboardData(payload)
        .then((response) => {
          const { data } = response;
          this.VoucherStats = data.VoucherStats;
          if (data.Status) {
            this.loaded = false;
            this.RepeatUserData = [];
            this.rangeData = data.Data[0];
            this.data = data.MovieWiseData;
            this.OrgData = data.OrganizationData;
            this.OrganizationData = data.CinemaWiseData;
            this.PaymentTypeData = data.PaymentTypeData;
            this.ConsessionData = data.ConsessionStats;
            this.RepeatUserData = data.RepeatUserStats || 0;
            this.GenreData = data.GenreStats || 0;
            this.SourceData = data.SourceStats || 0;
            this.DaysData = data.DaysStats || 0;
            this.TicketSoldData = data.TicketSoldStats || 0;
            let arrayDay = [];
            let arrayTicket = [];
            let arrayPercentage = [];
            this.DaysData.forEach((obj) => {
              arrayDay.push(obj.DayName);
              arrayTicket.push(obj.NoofTicket);
              arrayPercentage.push(obj.Percentage.replace("%", ""));
            });
            this.chartdata["labels"] = [];
            this.chartdata["datasets"] = [];
            this.chartdata["labels"] = arrayDay;
            this.chartdata["datasets"] = [
              {
                label: "Trending Day",
                backgroundColor: "#f87979",
                data: arrayTicket,
              },
              // {
              //   label: "Percentage",
              //   backgroundColor: "#cdcdcd",
              //   data: arrayPercentage
              // }
            ];
            this.loaded = true;
            EventBus.$emit("updateChart");

            this.OrganizationArray = this.OrganizationData.reduce(function (
              allNames,
              obj
            ) {
              let name = obj.OrganizationName.replace(/\s/, "");
              if (name in allNames) {
                allNames[name].push(obj);
              } else {
                allNames[name] = [obj];
              }
              return allNames;
            },
            {});
            console.log(this.OrganizationArray);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    variant: function (value) {
      let $variant;
      if (value <= 25) {
        $variant = "info";
      } else if (value > 25 && value <= 50) {
        $variant = "success";
      } else if (value > 50 && value <= 75) {
        $variant = "warning";
      } else if (value > 75 && value <= 100) {
        $variant = "danger";
      }
      return $variant;
    },
    flag: function (value) {
      return "flag-icon flag-icon-" + value;
    },
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
<style scoped>
#customId > .btn {
  background-color: transparent !important;
}
.input_height {
  height: 32.4px;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
  background: transparent !important;
}
</style>
