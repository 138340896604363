<script>
import { Line, Bar, mixins } from "vue-chartjs";
import { EventBus } from "@/main";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["options"],
  mounted() {
    EventBus.$on("updateChart", () => {
      this.renderChart(this.chartData, this.options);
    });

    this.renderChart(this.chartData, this.options);
    // this.renderChart({
    //   labels: [
    //     "January",
    //     "February",
    //     "March",
    //     "April",
    //     "May",
    //     "June",
    //     "July",
    //     "August",
    //     "September",
    //     "October",
    //     "November",
    //     "December"
    //   ],
    //   datasets: [
    //     {
    //       label: "Trending Day",
    //       backgroundColor: "#f87979",
    //       data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
    //     }
    //   ]
    // });
  }
};
</script>